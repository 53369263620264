import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";

import ScrollToTop from "../../Shared/ScrollToTop";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CircularProgress from "@mui/material/CircularProgress";

import { AiOutlineUser } from "react-icons/ai";

import "@fullcalendar/daygrid/main.css";
import "./Reservation.css";
import "./Reservation.scss";
import ReservationService from "../../../api/ReservationService";
import Avatar from "@mui/material/Avatar";

import { useSelector } from "react-redux";
import AddReservationPopUp from "./AddReservationPopUp/AddReservationPopUp";
import EditReservationPopUp from "./EditReservationPopUp/EditReservationPopUp";
import { selectUser } from "../../../Store/userSlice";

import { ToastContainer, toast } from "react-toastify";

const Reservation = () => {
  const user = useSelector(selectUser);
  const calendarRef = useRef(null);

  const [time, setTime] = useState("6:30");
  const [viewType, setViewType] = useState("timeGridDay");
  const [events, setEvents] = useState([
    {
      id: 1,
      title: "event 1",
      date: "2023-03-08T09:00:00",
      players: [
        {
          name: "Ramez Ben Aribia",
          coins: 1,
        },
        {
          name: "Mahdi Hamdi",
          coins: 1,
        },
        {
          name: "Mahdi Bouaziz",
          coins: 1,
        },
        {
          name: "Youssef Abid",
          coins: 1,
        },
      ],
      coins: 4,
      classNames: "busy",
    },
  ]);

  const [selectedEvent, setSelectedEvent] = useState(events[0]);

  const [modalEditReservationPopUp, setModalEditReservationPopUp] =
    useState(false);
  const [modalAddReservationPopUp, setModalAddReservationPopUp] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const [availableTime, setAvailableTime] = useState({});

  const [terrain, setTerrain] = useState("");
  const [terrainNames, setTerrainNames] = useState([]);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [viewLimitDate, setViewLimitDate] = useState(new Date());
  const [selectedDayTitle, setSelectedDayTitle] = useState("");
  const [isPrevDisabled, setIsPrevDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isTodayDisabled, setIsTodayDisabled] = useState(true);
  const [isReservationDisabled, setIsReservationDisabled] = useState(false);

  const today = useMemo(() => new Date(), []);

  const toggleEditReservationPopUp = () => {
    setModalEditReservationPopUp(!modalEditReservationPopUp);
  };
  const toggleAddReservationPopUp = () => {
    setModalAddReservationPopUp(!modalAddReservationPopUp);
  };

  const handleEventClick = useCallback(
    (info) => {
      let event = events.find(
        (item) => item._id === info.event._def.extendedProps._id
      );
      setSelectedEvent(event);
      toggleEditReservationPopUp();
    },
    [events]
  );

  const handleDateClick = useCallback((info) => {
    const date = new Date(info.dateStr);
    let selected_time =
      date.getHours().toString() + ":" + date.getMinutes().toString();

    if (date.getMinutes() === 0) {
      selected_time = selected_time + "0";
    }
    if (selected_time === "0:00") {
      setTime("6:30");
    } else {
      setTime(selected_time);
    }
    if (
      new Date(date).getTime() >= new Date().getTime() &&
      // TODO - DELETE THIS ONE WHEN THEY OPEN
      (selected_time === "15:30" ||
        selected_time === "17:00" ||
        selected_time === "18:30" ||
        selected_time === "20:00" ||
        selected_time === "21:30" ||
        selected_time == "23:00")
    ) {
      setSelectedDay(date);
      toggleAddReservationPopUp();
    } else if (new Date(date).getTime() < new Date().getTime()) {
      toast.warn(
        "Chers utilisateurs, il n'est pas possible de créer une réservation pour une date déjà passée.",
        {
          autoClose: 5000,
          theme: "light",
        }
      );
    } else {
      console.log("error");

      toast.warn(
        "Chers utilisateurs, les réservations ne sont acceptées que de 15h30 à 00h30 pour le moment.",
        {
          autoClose: 5000,
          theme: "light",
        }
      );
    }
  }, []);

  const truncateName = (name, maxLength = 15) => {
    if (name.length > maxLength) {
      return name.substring(0, maxLength - 2) + "...";
    }
    return name;
  };
  const handleDayDidMount = useCallback((args) => {
    setViewType(args.view.type);
    if (calendarRef.current) {
      changeCalendarTitle();
    }
  }, []);

  const renderEventContent = (eventInfo) => {
    const players_array = eventInfo.event._def.extendedProps.players;
    let players = [];
    for (let i = 0; i < players_array.length; i++) {
      let player = players_array[i];
      players.push(player);

      for (
        let i = player.nombreOfJetonsPayed + player.numberOfPlacesByCash;
        i > 1;
        i--
      ) {
        let clone_player = {
          userId: "invite",
          userFullName: "invite",
        };
        players.push(clone_player);
      }
    }
    for (let i = players.length; i < 4; i++) {
      let clone_player = {
        userId: "+",
        userFullName: "Ajouter",
      };
      players.push(clone_player);
    }

    return (
      <>
        <b>{eventInfo.timeText}</b>
        <div className="row event_content">
          {players.map((player, index) => (
            <div key={index} className="col-6 ">
              <div className="player custom-player">
                {player.userId === "+" || player.userId === "invite" ? (
                  <>
                    <Avatar sx={{ width: 24, height: 24 }}>
                      {player.userId === "+" ? (
                        <>+</>
                      ) : (
                        <>
                          <AiOutlineUser />
                        </>
                      )}
                    </Avatar>
                  </>
                ) : (
                  <>
                    {/* We don't need avatar here */}
                    {/* <Avatar
                      {...stringAvatar(player.userFullName)}
                      sx={{ width: 24, height: 24 }}
                    /> */}
                  </>
                )}

                {truncateName(player.userFullName)}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const getTerrainNames = async () => {
    await ReservationService.getTerrain()
      .then((response) => {
        let data = response.data;
        setTerrainNames(data);
        getReservationsByDate(selectedDay, data[0]._id);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const changeCalendarTitle = () => {
    const calendarApi = calendarRef.current.getApi();
    let currentviewTitle = calendarApi.currentDataManager.data.viewTitle;
    let TitleArray = currentviewTitle.split(" ");
    const nextTitle =
      TitleArray[0].split("/")[0] + " " + TitleArray[1] + " " + TitleArray[2];
    setSelectedDayTitle(nextTitle);
    setSelectedDay(calendarApi.currentDataManager.data.currentDate);
  };
  const formatDate = (date) => {
    return (
      date.getFullYear() +
      "-" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(date.getDate()).padStart(2, "0")
    );
  };
  const datesAreEqual = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const initAvailableTime = useCallback(() => {
    let initialAvailableTime = {};
    terrainNames.forEach((element) => {
      initialAvailableTime[element._id] = [
        // TODO - DELETE THIS ONE WHEN THEY OPEN
        // "6:30",
        // "8:00",
        // "9:30",
        // "11:00",
        // "12:30",
        // "14:00",
        "15:30",
        "17:00",
        "18:30",
        "20:00",
        "21:30",
        "23:00",
      ];
    });
    return initialAvailableTime;
  }, [terrainNames]);

  const getViewLimitDate = async () => {
    await ReservationService.getReservationDateViewLimit()
      .then((response) => {
        setViewLimitDate(new Date(response.data.data));
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getReservationsByDate = useCallback(
    async (date, terrain) => {
      setLoading(true);
      setTerrain(terrain);
      const initial_time = await initAvailableTime();
      setIsTodayDisabled(datesAreEqual(date, today));
      setSelectedDay(date);
      const formulated_day = formatDate(date);
      await ReservationService.getReservationsByDate(formulated_day, terrain)
        .then(async (response) => {
          let data = response.data;
          setEvents(data);
          setLoading(false);
          changeCalendarTitle();
          data.forEach((element) => {
            let start_hour = Number(element.start.split("T")[1].split(":")[0]);
            start_hour =
              start_hour + ":" + element.start.split("T")[1].split(":")[1];
            const start_hour_index = initial_time[terrain].indexOf(start_hour);
            if (start_hour_index !== -1) {
              initial_time[terrain].splice(start_hour_index, 1);
            }
          });

          if (datesAreEqual(date, today)) {
            const terrainTimes = initial_time[terrain];

            for (let i = terrainTimes.length - 1; i >= 0; i--) {
              const slot = terrainTimes[i];
              const [hours, minutes] = slot.split(":");

              const date_slot = new Date();
              date_slot.setHours(hours);
              date_slot.setMinutes(minutes);

              if (date_slot < today) {
                terrainTimes.splice(i, 1);
              }
            }
          }

          if (!initial_time[terrain].includes(time)) {
            setTime(initial_time[terrain][0]);
          }
          if (time === undefined) {
            setTime("6:30");
          }

          setAvailableTime(initial_time);
        })
        .catch((error) => {
          console.error("Error fetching reservations:", error);
          setLoading(false);
        });
    },
    [today, initAvailableTime, time]
  );

  const changeTerrain = useCallback(
    (event, newTerrain) => {
      setTerrain(newTerrain);
      getReservationsByDate(selectedDay, newTerrain);
    },
    [selectedDay, getReservationsByDate]
  );

  useEffect(() => {
    getTerrainNames();
    getViewLimitDate();
    const currentDate = new Date();

    // Define options for formatting the date
    const options = { day: "numeric", month: "long", year: "numeric" };

    // Format the date in French
    const formattedDate = currentDate.toLocaleDateString("fr-FR", options);

    setSelectedDayTitle(formattedDate);
  }, []);

  return (
    <>
      <Header />
      <div className="reservations_page_header">
        <h1 className="title title--h1 first-title title__separate">
          Réservations
        </h1>
      </div>

      <div className="container">
        <Tabs
          value={terrain}
          onChange={changeTerrain}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          className="reservation-tabs"
        >
          {terrainNames.map((item) => (
            <Tab
              className="reservation-single-tab"
              key={item._id}
              value={item._id}
              label={item.name}
            />
          ))}
        </Tabs>
        {loading ? (
          <div className="spiner_div">
            <CircularProgress size={"10rem"} />
          </div>
        ) : (
          <FullCalendar
            ref={calendarRef}
            timeZone="Africa/Tunis"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "PrevButton,NextButton TodayButton réserverButton",
              center: viewType === "timeGridDay" ? "titleButton" : "title",
              right: "refreshButton",
            }}
            views={{
              timeGridDay: {
                duration: { days: 1 },
                titleFormat: {
                  separator: "/",
                  month: "long",
                  year: "numeric",
                  day: "2-digit",
                },
              },
            }}
            customButtons={{
              réserverButton: {
                text: "réserver",
                click: () => {
                  if (
                    Object.keys(availableTime).length !== 0 &&
                    !availableTime[terrain].includes(time)
                  ) {
                    setTime(availableTime[terrain][0]);
                  }
                  const calendarApi = calendarRef.current.getApi();

                  setSelectedDay(
                    calendarApi.currentDataManager.data.currentDate
                  );

                  toggleAddReservationPopUp();
                },
              },
              PrevButton: {
                icon: "fc-icon fc-icon-chevron-left",
                click: () => {
                  const calendarApi = calendarRef.current.getApi();
                  const currentDate = calendarApi.getDate();
                  const prevDate = new Date(currentDate);
                  prevDate.setDate(currentDate.getDate() - 1);
                  setIsNextDisabled(false);
                  setIsPrevDisabled(prevDate < today);
                  setIsTodayDisabled(datesAreEqual(prevDate, today));
                  setIsReservationDisabled(prevDate < today);
                  calendarApi.prev();
                  getReservationsByDate(prevDate, terrain);
                },
              },
              NextButton: {
                icon: "fc-icon fc-icon-chevron-right",
                click: () => {
                  setIsPrevDisabled(false);
                  setIsReservationDisabled(false);
                  const calendarApi = calendarRef.current.getApi();
                  const currentDate = calendarApi.getDate();
                  const nextDate = new Date(currentDate);
                  nextDate.setDate(currentDate.getDate() + 1);
                  setIsNextDisabled(datesAreEqual(nextDate, viewLimitDate));
                  setIsTodayDisabled(datesAreEqual(nextDate, today));

                  calendarApi.next();
                  getReservationsByDate(nextDate, terrain);
                },
              },
              TodayButton: {
                text: "Aujourd'hui",
                click: () => {
                  setIsPrevDisabled(false);
                  setIsNextDisabled(false);
                  setIsTodayDisabled(true);
                  setIsReservationDisabled(false);
                  const calendarApi = calendarRef.current.getApi();
                  calendarApi.gotoDate(today);
                  getReservationsByDate(today, terrain);
                },
              },

              titleButton: {
                text: selectedDayTitle,
              },

              refreshButton: {
                text: "refresh",
                icon: "fc-icon-refresh",

                click: () => {
                  getReservationsByDate(selectedDay, terrain);
                },
              },
            }}
            initialView={viewType}
            initialDate={selectedDay}
            height="auto"
            eventDisplay="block"
            eventClick={handleEventClick}
            firstDay={0}
            locale={frLocale}
            slotMinTime="06:30"
            slotLabelInterval="01:30:00"
            slotDuration="01:30:00"
            slotMaxTime="24:30"
            dayCellDidMount={handleDayDidMount}
            viewDidMount={handleDayDidMount}
            selectable={true}
            dateClick={handleDateClick}
            events={events}
            eventContent={renderEventContent}
          />
        )}
      </div>

      <style>
        {`
          .fc-NextButton-button {
            background-color: ${
              isNextDisabled
                ? "var(--fc-button-bg-color, #2C3E50) !important"
                : "var(--color-primary)"
            };
            border-color: ${
              isNextDisabled
                ? "var(--fc-button-bg-color, #2C3E50) !important"
                : "var(--color-primary)"
            };
            opacity: ${isNextDisabled ? "0.65" : "1"};
            pointer-events: ${isNextDisabled ? "none" : "auto"};
            cursor: ${isNextDisabled ? "default" : "pointer"};
          }
          .fc-PrevButton-button {
            background-color: ${
              isPrevDisabled
                ? "var(--fc-button-bg-color, #2C3E50) !important"
                : "var(--color-primary)"
            };
            border-color: ${
              isPrevDisabled
                ? "var(--fc-button-bg-color, #2C3E50) !important"
                : "var(--color-primary)"
            };
            opacity: ${isPrevDisabled ? "0.65" : "1"};
            pointer-events: ${isPrevDisabled ? "none" : "auto"};
            cursor: ${isPrevDisabled ? "default" : "pointer"};
          }
          .fc-TodayButton-button {
            background-color: ${
              isTodayDisabled
                ? "var(--fc-button-bg-color, #2C3E50) !important"
                : "var(--color-primary)"
            };
            border-color: ${
              isTodayDisabled
                ? "var(--fc-button-bg-color, #2C3E50) !important"
                : "var(--color-primary)"
            };
            opacity: ${isTodayDisabled ? "0.65" : "1"};
            pointer-events: ${isTodayDisabled ? "none" : "auto"};
            cursor: ${isTodayDisabled ? "default" : "pointer"};
          }
          .fc-réserverButton-button {
            background-color: ${
              isReservationDisabled
                ? "var(--fc-button-bg-color, #2C3E50) !important"
                : "var(--color-primary)"
            };
            border-color: ${
              isReservationDisabled
                ? "var(--fc-button-bg-color, #2C3E50) !important"
                : "var(--color-primary)"
            };
            opacity: ${isReservationDisabled ? "0.65" : "1"};
            pointer-events: ${isReservationDisabled ? "none" : "auto"};
            cursor: ${isReservationDisabled ? "default" : "pointer"};
          }
        `}
      </style>

      {/* edit reservation (add player or cancel the reservation) pop up */}
      {user && (
        <EditReservationPopUp
          modal={modalEditReservationPopUp}
          toggle={toggleEditReservationPopUp}
          selectedEvent={selectedEvent}
          handleChange={changeTerrain}
          terrain={terrain}
          terrainNames={terrainNames}
        />
      )}
      {/* Add reservation pop up */}

      <AddReservationPopUp
        modal={modalAddReservationPopUp}
        toggle={toggleAddReservationPopUp}
        terrain={terrain}
        setTerrain={setTerrain}
        terrainNames={terrainNames}
        calendarRef={calendarRef}
        time={time}
        setTime={setTime}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        availableTime={availableTime}
        setAvailableTime={setAvailableTime}
        getReservationsByDate={getReservationsByDate}
      />

      <Footer hideContactInfo displayNewsLetter />

      <ScrollToTop />
      <ToastContainer position="top-right" theme="light" />
    </>
  );
};

export default Reservation;
