import React from "react";
import { Link } from "react-router-dom";
import racket from "../../../assets/images/Racket.png";

import "./Footer.css";

const Footer = () => {
  return (
    <>
      <footer className="site-footer text-uppercase bg-white">
        <div
          className="social-curve wow fadeInLeft"
          data-wow-duration="2s"
          data-wow-delay="0.3s"
        >
          <div className="container">
            <ul className="social-icon list-inline m-b0">
              <li>
                <span className="title">Suivez-nous</span>
              </li>

              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61559890747854"
                  target="_blank"
                  className="site-button-link instagram hover"
                >
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/casa_del_padel"
                  target="_blank"
                  className="site-button-link instagram hover"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-top">
          <div className="container">
            <div className="row footer-top-container">
              <div className="col-md-6 col-sm-6 col-lg-4">
                <div className="widget widget_getintuch">
                  <h4 className=" m-b30 text-white footer-title">
                    à propos de nous
                  </h4>
                  <ul className="info-contact">
                    <li>
                      <span>
                        Notre club, situé a Sidi Mansour, est un havre pour les
                        passionnés de padel. Fondé en 2024, nous nous engageons
                        à promouvoir ce sport dynamique et à créer une
                        communauté solide autour du padel.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-4">
                <div className="widget  widget_getintuch">
                  <h4 className="m-b30 text-white  footer-title">
                    COORDONNÉES
                  </h4>
                  <ul className="footer-top-ul">
                    <li>
                      <i className="ti-location-pin"></i>
                      <strong>ADRESSE</strong> Route Sidi Mansour km 6, Sfax{" "}
                    </li>
                    <li>
                      <i className="ti-mobile"></i>
                      <strong>TÉLÉPHONE</strong>58 444 440
                    </li>
                    <li>
                      <i className="ti-email"></i>
                      <strong>email</strong>Contact@casadelpadel.tn
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-4">
                <div className="widget  widget_services border-0 ">
                  <h4 className="m-b30 text-white footer-title">
                    Liens utiles
                  </h4>
                  <ul className="ft-social">
                    <li>
                      <Link to="/">Accueil</Link>
                    </li>
                    <li>
                      <Link to="/reservation">Reservation</Link>
                    </li>
                    <li>
                      <Link to="/tournois">Tournois</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contactez-nous</Link>
                    </li>
                  </ul>
                </div>
              </div>

              {/* <img className="footer-img" src={racket} alt="" /> */}
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                Copyright &copy; {new Date().getFullYear()}, Conçu par :
                <div>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/in/mahdibouaziz/"
                  >
                    Bouaziz Mahdi
                  </a>
                  &
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/in/ramez-ben-aribia/"
                  >
                    Ben Aribia Ramez
                  </a>
                  &
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/in/mahdi-hamdi-53b8151a5/"
                  >
                    Hamdi Mahdi
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
