import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import { useNavigate } from "react-router-dom";
import "./EventCard.css";
import { Link } from "react-router-dom";

const EventCard = ({ tournament }) => {
  const navigate = useNavigate();
  const [day, setDay] = useState(tournament.startDate);
  const [month, setMonth] = useState(tournament.startDate);
  const [year, setYear] = useState(tournament.startDate);

  useEffect(() => {
    var date = new Date(day);
    setDay(date.getUTCDate());
    const options = { month: "long" };
    setMonth(date.toLocaleString("en-US", options));
    setYear(date.getUTCFullYear());
  }, []);
  return (
    <>
      <Card sx={{ maxWidth: 345 }} className="card-container fade-in-image">
        <CardActionArea 
          className="card-action-area"
          onClick={() => {
            navigate("/tournois/event/" + tournament._id);
          }}
        >
          <div className="card_date_div">
            <CardMedia
              component="img"
              height="140"
              image={tournament.picture}
              // className="dlab-media dlab-img-overlay1 dlab-img-effect"
              alt="green iguana"
              className="dlab_box_image card-media"
            />
            <div className="card_date_view">
              <h6>{month}</h6>
              <h6>{day}</h6>
              <h6>{year}</h6>
            </div>
          </div>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {tournament.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {tournament.description}
            </Typography>
          </CardContent>
          <CardActions className="card-action" >
            <Link
              className="btn  btn_subcribe"
              to={"/tournois/event/" + tournament._id}
            >
              S'inscrire
            </Link>
          </CardActions>
        </CardActionArea>
      </Card>
    </>
  );
};

export default EventCard;
